<template>

  <div>
    <b-card>
      <AgGrid
        ref="ADCVDGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Query By Case #'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="OpenCreateByCase()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">By Case #</span></b-button>&nbsp;
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Query By Criteria'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="OpenCreateByCriteria()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">By Criteria</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateCaseModal"
      ref="CreateByCase"
      title="New AD/CVD Query"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="CaseRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="required|max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case1"
                    v-model="Case.case1"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case2"
                    v-model="Case.case2"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case3"
                    v-model="Case.case3"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case4"
                    v-model="Case.case4"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case5"
                    v-model="Case.case5"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case6"
                    v-model="Case.case6"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case7"
                    v-model="Case.case7"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case8"
                    v-model="Case.case8"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case9"
                    v-model="Case.case9"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Case #:</b></i></label>
                  <b-form-input
                    id="case10"
                    v-model="Case.case10"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Case Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="createbycase()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="CreateCriteriaModal"
      ref="CreateByCriteria"
      title="New AD/CVD Query"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="CriteriaRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case Status"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Case Status:</b></i></label>
                  <v-select
                    id="CaseStatus"
                    v-model="criteria.CaseStatus"
                    :options="CaseStatusOptions"
                    label="text"
                    placeholder="Select a Status"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Country:</b></i></label>
                  <v-select
                    id="CountryCode"
                    v-model="criteria.CountryCode"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="HTS #"
                rules="max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>HTS #:</b></i></label>
                  <b-form-input
                    id="HTSNumber"
                    v-model="criteria.HTSNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="HTS Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="TSUSA #"
                rules="max:7"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>TSUSA #:</b></i></label>
                  <b-form-input
                    id="TSUSA"
                    v-model="criteria.TSUSA"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="TSUSA Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MID #"
                rules="max:15"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>MID #:</b></i></label>
                  <b-form-input
                    id="MID"
                    v-model="criteria.MID"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="MID Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Foreign Exporter"
                rules="max:15"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Foreign Exporter:</b></i></label>
                  <b-form-input
                    id="ForeignExporter"
                    v-model="criteria.ForeignExporter"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Foreign Exporter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Case #"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Date Since Last Update:</b></i></label>
                  <b-form-datepicker
                    v-model="criteria.LastUpdate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="createbycriteria()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIADCVDViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      casenumber: '',
      CaseStatusOptions: [
        { text: 'Active', value: 'A' },
        { text: 'Inactive', value: 'I' },
        { text: 'Both', value: 'B' },

      ],
      CountryOptions: [],
      Case: {
        case1: '',
        case2: '',
        case3: '',
        case4: '',
        case5: '',
        case6: '',
        case7: '',
        case8: '',
        case9: '',
        case10: '',
      },
      criteria: {
        CaseStatus: '',
        CountryCode: '',
        HTSNumber: '',
        TSUSA: '',
        MID: '',
        ForeignExporter: '',
        LastUpdate: null,
      },

    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ADCVD')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'caseNumber',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Status', field: 'status', minWidth: 110,
      },
      {
        headerName: 'Case #', field: 'caseNumber', minWidth: 115,
      },
      {
        headerName: 'Related Case #', field: 'relatedCaseNumber', minWidth: 165,
      },
      {
        headerName: 'Official Name', field: 'officialCaseName', minWidth: 200,
      },
      {
        headerName: 'Type', field: 'caseType', minWidth: 120,
      },
      {
        headerName: 'Country', field: 'countryCode', minWidth: 120,
      },
      {
        headerName: 'Description', field: 'description', minWidth: 300,
      },
      {
        headerName: 'Effective Date', field: 'effectiveDate', minWidth: 175,
      },
      {
        headerName: 'Last Updated', field: 'lastUpdated', minWidth: 175,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ADCVDGrid.saveState('ADCVD') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ADCVDGrid.saveState('ADCVD') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ADCVDGrid.saveState('ADCVD') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ADCVDGrid.saveState('ADCVD') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(casenumber) {
      this.casenumber = casenumber
      this.$router.push(`/abi/query/adcvd/detail/${casenumber}`)
    },
    DoubleClickOpenPopup(params) {
      this.casenumber = params.data.caseNumber
      this.$router.push(`/abi/query/adcvd/detail/${params.data.caseNumber}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadADCVD(), this.LoadCountryList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadADCVD() {
      axios.get('/abi/query/adcvd/all')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ADCVDGrid.ResetColumns()
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    OpenCreateByCase() {
      this.$refs.CreateByCase.show()
    },
    OpenCreateByCriteria() {
      this.$refs.CreateByCriteria.show()
    },
    createbycase() {
      this.$refs.CaseRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ADCVDCaseQuery = {
            Case1: this.Case.case1,
            Case2: this.Case.case2,
            Case3: this.Case.case3,
            Case4: this.Case.case4,
            Case5: this.Case.case5,
            Case6: this.Case.case6,
            Case7: this.Case.case7,
            Case8: this.Case.case8,
            Case9: this.Case.case9,
            Case10: this.Case.case10,
          }

          axios.post('/abi/query/adcvd/case', ADCVDCaseQuery)
            .then(() => {
              this.LoadingEnd()
              this.$refs.CreateByCase.hide()
              this.DisplaySuccess('Query data has been submitted.').then(() => {
                this.LoadADCVD()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateByCase.hide()
              this.DisplayError('Query cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Query cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    createbycriteria() {
      this.$refs.CriteriaRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ADCVDCriteriaQuery = {
            CaseStatus: this.criteria.CaseStatus.value,
            CountryCode: this.CheckNull(this.criteria.CountryCode) ? this.criteria.CountryCode.countryCode : '',
            HTSNumber: this.criteria.HTSNumber,
            TSUSA: this.criteria.TSUSA,
            MID: this.criteria.MID,
            ForeignExporter: this.criteria.ForeignExporter,
            LastUpdate: new Date(this.criteria.LastUpdate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.criteria.LastUpdate).toJSON().split('T')[0] : '',
          }

          axios.post('/abi/query/adcvd/criteria', ADCVDCriteriaQuery)
            .then(() => {
              this.LoadingEnd()
              this.$refs.CreateByCriteria.hide()
              this.DisplaySuccess('Query data has been submitted.').then(() => {
                this.LoadADCVD()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateByCriteria.hide()
              this.DisplayError('Query cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Query cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Delete(casenumber) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/abi/query/adcvd/${casenumber}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('AD/CVD has been removed.')
              .then(() => {
                // redirect
                this.LoadADCVD()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove AD/CVD.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
